import AbastoImg from './ABASTO.png'
import HarvestImg from './HARVEST.png'
import EstadosImg from './EERR-11.png'
import SistemasImg from './sef-10.png'
import ContabilidadImg from './ContaMTS_1.png'
import AdministracionImg from './Adm-06.png'
import ListaServicio from '../ListaServicio/ListaServicio'
import AdminFoto from './fotos/admin-foto.jpg'
import ContaFoto from './fotos/conta-foto.jpg'
import EstadoFoto from './fotos/estado-foto.jpg'
import HarvestFoto from './fotos/harvest-foto.jpg'
import SistemasFoto from './fotos/sistemas.JPEG'
import AbastoFoto from './fotos/fondo.jpg'

import './Servicios.css'

const Servicios =()=>{



    const servicios=[{id:"1", pagina: '/Abasto', titulo:"Abasto", imag:AbastoImg, foto:AbastoFoto, categoria:"radial_center", categoriaImg:"radial_center_img", resumen: "Abasto es un sistema de gestión comercial integral para la administración de empresas frutihortícolas."},
                    {id:"7", pagina: '/Harvest', titulo:"Harvest", imag:HarvestImg, foto:HarvestFoto, categoria:"radial_edge", categoriaImg:"radial_edge_img", resumen: "Harvest es una aplicación para dispositivos móviles, tablets, integrada al sistema de gestión Abasto."},
                    {id:"2", pagina: '/Administracion', titulo:"Administración y RRHH", imag:AdministracionImg, foto:AdminFoto, categoria:"radial_edge_edge", categoriaImg:"radial_edge_edge_img", resumen: "Ofrecemos un servicio de consultoría dedicado a identificar y abordar las necesidades específicas en la administración empresarial."}, 
                    {id:"5", pagina: '/ContaMTS', titulo:"ContaMTS", imag:ContabilidadImg, foto:ContaFoto, categoria:"radial_edge_media", categoriaImg:"radial_edge_media_img", resumen: "ContaMTS es un sistema de contabilidad diseñado para el sector frutihortícola integrado a Abasto."}, 
                    {id:"3", pagina: '/SEF', titulo:"Sistemas de empaque frutihorticola", imag:SistemasImg, foto:SistemasFoto, categoria:"radial_edge", categoriaImg:"radial_edge_img", resumen: "SEF es un módulo integrado a Abasto para el control de la trazabilidad de las empresas productoras."}, 
                    {id:"4", pagina: '/EERR', titulo:"Estados de resultado", imag:EstadosImg, foto:EstadoFoto, categoria:"radial_edge_media", categoriaImg:"radial_edge_media_img", resumen: "Los Estados de Resultados,  son una herramienta de análisis de los costos, las utilidades reales y la evolución patrimonial de una empresa."},
                ]



    return(
        <div className='pagina'>
        <h1>Servicios</h1>
        <div className="lista-servicio">
            <ListaServicio servicios={servicios}/>
            
        </div>
        </div>
    )

}

export default Servicios