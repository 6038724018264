import { Button } from "@mui/material"

const SEF =()=>{
    return(
        <div className='pagina'> 
            <h1>SEF</h1>
            <h4>El Sistema de Empaque Frutihortícola (SEF) es un módulo integrado a Abasto para el control de la trazabilidad de las empresas productoras.</h4>
            <p>Proporciona un seguimiento de las mercaderías al detalle, desde el galpón de empaque hasta la consignación o venta de los productos.<br/>
            Permite un seguimiento del artículo en cuestión, en cuanto al rinde por kilos, las mermas, el producido real y, al mismo tiempo, el control de todos los insumos utilizados para el empaque (envases, pallets, cartones, zunchos, stickers, etc).<br/>
            El SEF presta los resultados para hacer la generación de informes de producción. Estas herramientas permiten a los productores optimizar sus operaciones, reducir los costos y mejorar la eficiencia en todas las etapas del proceso de empaque y distribución.<br/>
            </p>
            <Button href='/servicios' variant="outlined" color='terciary'>Volver a servicios</Button>

        </div>
    )
}

export default SEF