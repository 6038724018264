import { Button, Box , Grid} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useForm } from "../../hooks/useForm"
import { useState } from "react"
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';



const FormularioContacto=()=>{

    const { values, handleInputChange } = useForm({
        nombre: '',
        email: '',
        asunto: '',
    })

    const [enviado, setEnviado] = useState(false);

    const confirmacion =()=>{
        console.log(values)

        if(values.nombre === ""){
            alert("Por favor, ingresa un nombre válido.")
        }
        else if(values.asunto === ""){
            alert("Por favor, ingresa un asunto.")
        }
        else if (typeof values.email !== "undefined") {
            let lastAtPos = values.email.lastIndexOf('@');
            let lastDotPos = values.email.lastIndexOf('.');
    
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && values.email.indexOf('@@') === -1 && lastDotPos > 2 && (values.email.length - lastDotPos) > 2)) {
                alert( "Por favor, ingresa un correo válido.");
                }else{
                consulta()
            }

        }

    }

    const consulta =()=>{

        const consult = {
                nombre: values.nombre,
                email: values.email,
                asunto: values.asunto
            }
        console.log(consult)
        setEnviado(true)

        emailjs.sendForm('service_hs78t4e', 'template_wdhxdtd', "form", 'teldezeg-jyKffK6n')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

    }
    if (enviado === true) {
        return(
            <div className="consulta-enviada">
            <Grid item>
                <h2>Consulta enviada</h2>
                <div>
                    <p>Gracias por tu consulta. Te contestaremos a la brevedad.</p>
                    <Button variant="contained" color="secondary" component={Link} to="/">Volver al inicio</Button>
                </div>
            </Grid>
            </div>
        )
    }




    return(
        <Grid item>
        <div>
            <Box component="form"
            sx={{'& .MuiTextField-root': { m: 1.5, width: '35ch' }}}
            autoComplete="off" >
            <TextField
            required="true"
            label="Nombre"
            name="nombre"
            type={'nombre'} 
            variant="filled" 
            onChange={handleInputChange}
            value={values.nombre}
            /><br/>
            <TextField
            required="true"
            label="Email"
            name="email"
            type={'email'} 
            variant="filled" 
            onChange={handleInputChange}
            value={values.email}
            /><br/>
            <TextField
            required="true"
            label="Asunto"
            name="asunto"
            type={'asunto'} 
            multiline={true}
            rows="6"
            variant="filled" 
            onChange={handleInputChange}
            value={values.asunto}
            /><br/>
            <div className="enviar">
                <Button variant="contained" color="secondary"
                onClick={confirmacion}>Enviar</Button>
            </div>
            </Box>
        </div>
    </Grid>
    )
}

export default FormularioContacto