import Servicio from "../Servicio/Servicio.js";

function ListaServicio({servicios = []}){


    
    return (
    <div>
        <div  className="radial" >
            { servicios.map((prod) => <Servicio  servicio={prod} key={prod.id}/>)}
        </div>
    </div>
        )
}


export default ListaServicio