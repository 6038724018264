import './MTS.png'
import { Link } from 'react-router-dom';
import Botonera from '../Botonera/Botonera';
import React from 'react'
import './Cabezal.css'

const Cabezal=()=>{

        return (
        <nav className='nav'>
            <div className="box">
                <div className='logo'>
                <Link to={"/"} href="#/">
                    <img src={require('./MTS.png')} alt="logo"/><p className='titulo'></p>
                </Link>
                </div>
                <Botonera/>
            </div>
        </nav>
        )

    
}

export default Cabezal