import {Facebook, WhatsApp, Instagram, Phone} from '@mui/icons-material';
import { Button, ButtonGroup, Box , Grid} from '@mui/material';


const BotoneraRedes =()=>{
 
 return(<div><Grid item>
<ButtonGroup variant="font" orientation='vertical'>
    <Button size="large" href="https://www.facebook.com/profile.php?id=100091058551836&is_tour_dismissed=true" target="_blank"><Facebook/>Mts Consultora Srl</Button>
    <Button size="large" href="https://www.instagram.com/mtsconsultorasrl/" target="_blank"><Instagram/>@mtsconsultorasrl</Button>
    <Button size="large" href="https://wa.me/541125604062" target="_blank"><WhatsApp/>Chat</Button>

</ButtonGroup>
</Grid>
    <h4 ><br/>Autopista Pablo Riccheri <br/> (Mercado Central), <br/>Piso 3º, Of. 329</h4>
    </div>)
}


export default BotoneraRedes

