import NuestrosClientes from '../NuestrosClientes/NuestrosClientes.js'
import './Nosotros.css'
const Nosotros =()=>{


    return(
        <div className="pagina">
            <h1 color="primary">Sobre nosotros</h1>
            <p className="desc-nosotros">MTS Consultora SRL es una empresa dedicada, principalmente, al desarrollo de sistemas informáticos de diverso tipo, para la gestión de empresas productoras y consignatarias de frutas, hortalizas y verduras. Atendemos a los distintos problemas de esta actividad con un enfoque multidisciplinario. Trabajamos desde el área comercial hasta los estados de resultados de las empresas que confían en nosotros.</p>
            <p className="desc-nosotros">Contamos con más de 30 años de experiencia aportando al desarrollo y la consolidación de empresas en el Mercado Central de Buenos Aires, donde se encuentran nuestros comienzos a fines de la década del 80 y en los numerosos mercados de toda la Argentina (Rosario, Córdoba, Santa Fe, entre otros) con lo cual nos definimos como una empresa nacional. Nos concentramos en ayudar a nuestros usuarios a desarrollar métodos de trabajo y control prácticos y efectivos acordes a esta particular actividad.</p>
            <p className="desc-nosotros">El nuestro es un equipo de trabajo con muchos años de experiencia y altamente profesionalizado que asiste en las distintas áreas y necesidades de su empresa, con un servicio inmediato y comprometido con el cliente.</p>
            <p className="desc-nosotros">Estamos a la vanguardia en sistemas informáticos desde hace más de 30 años. Como muestra de esto incorporamos al equipo Harvest para el desarrollo de aplicaciones para dispositivos móviles lo cual nos permite, hoy como ayer, proveer soluciones integrales que ayuden a su empresa a optimizar la administración, para una mejor toma de decisiones empresariales.</p>
            <NuestrosClientes/>
        </div>
    )
}

export default Nosotros