import './App.css';
import Cabezal from './Componentes/Cabezal/Cabezal';
import Servicios from './Componentes/Servicios/Servicios';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Slider from './Componentes/Slider/Slider';
import Nosotros from './Componentes/Nosotros/Nosotros';
import Contacto from './Componentes/Contacto/Contacto';
import Harvest from './Componentes/ServiciosPag/Harvest/Harvest';
import Abasto from './Componentes/ServiciosPag/Abasto/Abasto';
import ContaMTS from './Componentes/ServiciosPag/ContaMTS/ContaMTS';
import Administracion from './Componentes/ServiciosPag/Administracion/Administracion';
import EERR from './Componentes/ServiciosPag/EERR/EERR';
import SEF from './Componentes/ServiciosPag/SEF/SEF';

function App() {


  return (
    <div className="App">
      <BrowserRouter>
        <Cabezal/>

          
          <Routes>
            <Route path="/" element={<Slider/>}/>
            <Route path="/servicios" element={<Servicios/>}/>
            <Route path="/nosotros" element={<Nosotros/>}/>
            <Route path="/contacto" element={<Contacto/>}/>
            <Route path="/Harvest" element={<Harvest/>}/>
            <Route path="/Abasto" element={<Abasto/>}/>
            <Route path="/ContaMTS" element={<ContaMTS/>}/>
            <Route path="/Administracion" element={<Administracion/>}/>
            <Route path="/EERR" element={<EERR/>}/>
            <Route path="/SEF" element={<SEF/>}/>
          </Routes>
        
      </BrowserRouter>
    </div>
  );
}

export default App;
