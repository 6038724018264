import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Abasto from './Abasto-fondo.png'
import Harvest from './harvest-foto.jpg'
import AbastoLogo from './ABASTO.png'
import HarvestLogo from './HARVEST1.png'
import Landing from './../Landing/Landing'
import ContaLogo from './Marca_ContaMTS.png'
import MTSLogo from './MTS.png'
import Conta from './Conta.png'
import MTS from './MTS-Img.png'
import SEFFondo from './SEF-fondo.png'
import SEFLogo from './sef-10.png'
import EERR from './EERR-11.png'
import EERRFondo from './estado-foto.jpg'




function Slider(props)
{
    var items = [
        {
            imag: Abasto,
            logo: AbastoLogo,
            texto: "Abasto es un sistema de gestión comercial integral para la administración de empresas frutihortícolas.",
            pagina: '/Abasto'},
        {
            imag: Harvest,
            logo:HarvestLogo,
            texto: "Harvest es una aplicación para dispositivos móviles, tablets, integrada al sistema de gestión Abasto.",
            pagina: '/Harvest'
        },
        {
            imag: Conta,
            logo: ContaLogo,
            texto: "ContaMTS es un sistema de contabilidad diseñado para el sector frutihortícola integrado a Abasto."
            ,pagina: '/ContaMTS'
        },
        {
            imag: SEFFondo,
            logo: SEFLogo,
            texto: "SEF es un módulo integrado a Abasto para el control de la trazabilidad de las empresas productoras."
            ,pagina: '/SEF'
        },
        {
            imag: MTS,
            logo:MTSLogo,
            texto: "MTS Consultora SRL es una empresa dedicada al desarrollo de sistemas de gestión para empresas del rubro frutihortícola."
            ,pagina: '/Nosotros'        
        },
        {
            imag: EERRFondo,
            logo:EERR,
            texto: "Los Estados de Resultados son una herramienta de análisis de los costos, las utilidades reales y la evolución patrimonial de una empresa."
            ,pagina: '/EERR'        
        },
    ]

    return (
        <div>
        <Carousel        
        interval={10000}
        navButtonsAlwaysVisible={false}
        navButtonsProps={{ 
            style: {
                opacity: 0.4
            }
        }}
        indicatorContainerProps={{
            style: {
            zIndex: 7,
            marginTop: "580px",
            }
        }}

>   
            {
                items.map( (item, i) => <Landing key={i} item={item} /> )
            }
            
        </Carousel>
        </div>
    )
}



export default Slider