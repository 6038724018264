import almana from './Logos/6Almana.JPG'
import juanes from './Logos/Juanes.jpeg'
import sasha from './Logos/1Sasha.jpg'
import borreguito from './Logos/2Borreguito.jpg'
import felipe from './Logos/felipe.jpg'
import herederos from './Logos/herederos.jpg'
import sama from './Logos/Sama.png'
import central from './Logos/Central.jpg'
import barcelo from './Logos/5Barcelo.jpg'
import agrofortaleza from './Logos/AgroFortaleza.jpg'
import bendita from './Logos/Bendita.jpg'
import anele from './Logos/Anele.jpeg'
import tango from './Logos/Tango.JPG'
import fruit from './Logos/FruitPlate.jpg'
import caballero from './Logos/Caballero.jpeg'
import papa from './Logos/Papa.jpeg'
import maxmar from './Logos/Max-mar.jpeg'
import romero from './Logos/Romero.jpeg'
import emporio from './Logos/Emporio.jpeg'
import ayrtons from './Logos/1ayrtons.jpg'
import quinterito from './Logos/Quinterito.jpeg'
import pr from './Logos/7PR.JPG'
import pibes from './Logos/pibes.jpg'
import manel from './Logos/3Manel.JPG'
import Carousel from 'react-material-ui-carousel';

import Clientes from '../Clientes/Clientes.js'


function NuestrosClientes(props){



    const clientes =[
        {id:"1", nombre:"Borreguito", logo:borreguito, lugar:"Córdoba", nombre2:"Central Ventas", logo2:central, lugar2:"MCBA", nombre3:"Papa Group", logo3:papa, lugar3:"Calzada, Beccar, Campito, Quilmes"},
        {id:"2", nombre:"Fruticola Sasha", logo:sasha, lugar:"MCBA", nombre2:"Frutícola PR", logo2:pr, lugar2:"MCBA",nombre3:"El Emporio del Ajo", logo3:emporio, lugar3:"Rosario"},
        {id:"3", nombre:"Felipe hermanos", logo:felipe,lugar:"Villa María", nombre2:"Ayrtons", logo2:ayrtons, lugar2:"MCBA", nombre3:"El Quinterito", logo3:quinterito, lugar3:"MCBA"},
        {id:"4", nombre:"Los Herederos", logo:herederos, lugar:"MCBA", nombre2:"Los Juanes", logo2:juanes, lugar2:"Santa Fe", nombre3:"AgroFortaleza", logo3:agrofortaleza, lugar3:"MCBA"},
        {id:"5", nombre:"Sama", logo:sama, lugar:"MCBA",  nombre2:"Max-Mar", logo2:maxmar, lugar2:"Rosario", nombre3:"Bendita Tierra", logo3:bendita, lugar3:"Beccar"},
        {id:"6", nombre:"Almaná", logo:almana, lugar:"MCBA", nombre2:"Anele Ruiz", logo2:anele, lugar2:"3 de Febrero", nombre3:"Tango Fruits", logo3:tango, lugar3:"MCBA"},
        {id:"7", nombre:"Romero", logo:romero, lugar:"Córdoba", nombre2:"Caballero Sabino", logo2:caballero,  lugar2:"Santa Fe", nombre3:"Manel", logo3:manel, lugar3:"MCBA"},
        {id:"8", nombre:"Fruit Plate", logo:fruit, lugar:"Avellaneda, Quilmes", nombre2:"Los 4 Pibes", logo2:pibes, lugar2:"MCBA", nombre3:"Barceló", logo3:barcelo,  lugar3:"MCBA"},
    ]


    return(
        
        <div>
        <h2>Algunos de nuestros clientes</h2>
        
<Carousel        
        interval={4000}
        navButtonsAlwaysVisible={false}
        navButtonsProps={{ 
            style: {
                opacity: 0.4
            }
        }}
        animation={"slide"}
        duration={1000}
        indicatorContainerProps={{
            style: {
            zIndex: 7,
            }
        }}

>   
                {
                    clientes.map((clientes, i) => {
                        return <Clientes clientes={clientes} key={i} contentPosition={clientes.contentPosition} />
                    })
                }

           
            </Carousel>
            
        </div>
    )
}


export default NuestrosClientes