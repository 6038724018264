import { Button } from "@mui/material"

const ContaMTS =()=>{
    return(
        <div className='pagina'>
            <h1>ContaMTS</h1>
            <h4>ContaMTS es un sistema de contabilidad diseñado para el sector frutihortícola integrado a Abasto.</h4>
            <p>Esto permite simplificar el correcto seguimiento de las normativas fiscales y laborales del sector, utilizando la información cargada en ABASTO con anterioridad.<br/>
            Al estar dirigido a la industria frutihortícola y los requisitos específicos del área, permite a las empresas mantener un control preciso sobre sus obligaciones, para la presentación de las declaraciones juradas mensuales y los aplicativos correspondientes, evitando errores y multas.<br/>
            ContaMTS administra la gestión de facturas y pagos, liquidaciones, gastos, listados diarios, hasta la generación del balance de la empresa. <br/>
            </p>
            <Button href='/servicios' variant="outlined" color='terciary'>Volver a servicios</Button>

        </div>
    )
}

export default ContaMTS