import {Grid, Paper} from '@mui/material';
import './Clientes.css'

function Clientes(props)
{
   

        return (<div className='tarjetas'>
            
        <div className="tarjeta">
            <div className="foto-carousel-clientes">
                <img src={props.clientes.logo} className="carousel-clientes" alt="abasto mercado"></img>
            </div>
            <div className='fade-in-clientes'>            
                <p className="carousel-clientes-texto">{props.clientes.nombre} </p>
                <p className="clientes-lugar">{props.clientes.lugar} </p>

            </div>
        </div>
        
        
        <div className="tarjeta">
            <div className="foto-carousel-clientes">
                <img src={props.clientes.logo2} className="carousel-clientes" alt="abasto mercado"></img>
            </div>
            <div className='fade-in-clientes'>            
                <p className="carousel-clientes-texto">{props.clientes.nombre2} </p>
                <p className="clientes-lugar">{props.clientes.lugar2} </p>

            </div>
        </div>

        <div className="tarjeta">
            <div className="foto-carousel-clientes">
                <img src={props.clientes.logo3} className="carousel-clientes" alt="abasto mercado"></img>
            </div>
            <div className='fade-in-clientes'>            
                <p className="carousel-clientes-texto">{props.clientes.nombre3} </p>
                <p className="clientes-lugar">{props.clientes.lugar3} </p>

            </div>
        </div>
        
                </div>

        )


}


export default Clientes