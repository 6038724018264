import BotoneraRedes from '../BotoneraRedes/BotoneraRedes';
import FormularioContacto from '../FormularioContacto/FormularioContacto';
import InstagramEm from '../InstagramEm/InstagramEm';
import './Contacto.css'

const Contacto = () =>{


    
    return(
        <div className="pagina-contacto">
            <h1>Contacto</h1>
            <div className='tarjetas-contacto'>
                <div className="tarjeta-contacto"><FormularioContacto/></div>
                <div className="tarjeta-contacto"><BotoneraRedes/></div>
                <div className="tarjeta-contacto"><InstagramEm/></div>
            </div>
        </div>
    )
}

export default Contacto;


