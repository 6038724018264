import { Button } from "@mui/material"

const EERR =()=>{
    return(
        <div className='pagina'>
            <h1>EERR</h1>
            <h4>Los Estados de Resultados, que realizamos en MTS, son una herramienta de análisis de los costos, las utilidades reales y la evolución patrimonial de una empresa.</h4>
            <p>Ofrecemos un servicio particular para cada empresa con resultados mensuales, a partir de la información cargada en el sistema Abasto.<br/>
            Estos informes se componen de dos partes: <br/>
            <ul><li>La primera es la utilidad bruta de las partidas y lotes vendidos en el mes, a partir de los costos y las ventas de la mercadería. Con resultados por proveedor, resultados totales y con el detalle de cada partida ingresada.</li>
            <li>La segunda parte es el análisis de los ingresos de esta utilidad, los gastos fijos y móviles durante el mes en cuestión, ofreciendo una radiografía precisa de la posición financiera de la empresa mes a mes.</li>
            </ul>
            La capacidad de comparar los Estados de Resultados de diferentes meses permite detectar tendencias y patrones de comportamiento financiero a lo largo del tiempo. Esta información es clave para la toma de decisiones estratégicas, como la planificación de presupuestos, la asignación de recursos y la identificación de oportunidades de crecimiento.
            </p>
            <Button href='/servicios' variant="outlined" color='terciary'>Volver a servicios</Button>

        </div>
    )
}

export default EERR