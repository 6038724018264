import { InstagramEmbed } from 'react-social-media-embed';
import { Grid } from '@mui/material';

const InstagramEm=()=>{

return(
    <Grid item>
    <div style={{ display: 'flex', justifyContent: 'center', width: "328px" }}>
        <InstagramEmbed url="https://www.instagram.com/p/C2k2DCzpR8j/" width={328} />
    </div>
    </Grid>
)


}


export default InstagramEm