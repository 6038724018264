import './Landing.css'
import { Paper, Button } from '@mui/material'


    function Landing(props)
    {
        return (<Paper >
                    <div >
                    <img src={props.item.imag} className="imagen-land" alt="abasto mercado"></img>
                    <div className="fade-in-image">            
                        <img src={props.item.logo} className="logo-abasto-land" alt="logo Abasto"></img>
                        <p className="texto-llegada">{props.item.texto}</p>
                        <Button href={props.item.pagina} variant="contained" color='primary'>Ver más</Button>
                    </div>
                    </div>
                </Paper>
        )
    }



export default Landing