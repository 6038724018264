import { Button } from "@mui/material"


const Abasto =()=>{
    return(
        <div className='pagina'>
            <h1>Abasto</h1>
            <h4>Abasto es un sistema de gestión comercial integral para la administración de empresas frutihortícolas. </h4>
            <p>Este sistema está diseñado para centralizar la información de los diversos módulos, simplificar la administración y controlar los resultados en tiempo real. Desde pequeños productores hasta grandes distribuidores. <br/>
            Abasto permite gestionar eficientemente cada aspecto del proceso en el rubro frutihortícola. Desde la producción, pasando por la venta y terminando con los costos o liquidaciones. Los módulos integrados permiten llevar un seguimiento detallado de la cadena de suministro, gestionar inventarios, administrar pedidos y ventas, y mucho más.<br/>
            Un sistema que contiene todas las herramientas necesarias, pero que se adapta a las  particularidades de cada empresa. Consignación y compra de mercaderías, notas de pedido, remitos, ventas, cuentas corrientes, caja, liquidaciones, envases, bancos, aplicativos fiscales, informes y promedios, empaque, etc. <br/>
            La centralización de Abasto en los módulos simplifica, significativamente, la carga de datos al evitar la duplicación de información. Al tener todos los procesos integrados en un único sistema, los datos se ingresan una sola vez y se cotejan entre todos los módulos para minimizar las pérdidas de bultos o haberes, eliminando la necesidad de introducir la misma información repetidamente en diferentes plataformas o sistemas. Abasto reduce los errores, ahorra tiempo y garantiza la eficacia y precisión de los datos de la empresa.
            </p>

            <Button href='/servicios' variant="outlined" color='terciary'>Volver a servicios</Button>
        </div>
    )
}

export default Abasto