import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';



const theme = createTheme({
  palette:{
    primary: {
      main: grey[800],
    },
    secondary:{
      main: '#DFC18D'
    },
    terciary:{
      main: '#000000'
    },
  },
  typography: {
    button: {
      fontFamily: 'Ruda',
      textTransform: 'initial',
      fontSize: 18,
      color: grey[900]
    },
    p:{
      fontFamily: 'Ruda',
    }


  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
