import { Button } from "@mui/material"

const Administracion =()=>{
    return(
        <div className='pagina'>
            <h1>Administración</h1>
            <h4>Ofrecemos un servicio de consultoría dedicado a identificar y abordar las necesidades específicas en la administración empresarial. </h4>
            <p>Nuestro servicio de consultoría aborda las problemáticas de la empresa en cuestión ofreciendo, primero, un diagnóstico de la situación, para continuar con la formación y capacitación del equipo de trabajo, mejorando las metodologías y herramientas de todo el equipo. <br/>
            Contamos con profesionales que trabajan desde hace más de 30 años en los mercados de todo el país, para poder dar respuestas especializadas a las problemáticas específicas de la empresa y del puesto.
            </p>

            <Button href='/servicios' variant="outlined" color='terciary'>Volver a servicios</Button>

        </div>
    )
}

export default Administracion