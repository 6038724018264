import { Button } from "@mui/material"

const Harvest =()=>{
    return(
        <div className='pagina'>
            <h1>Harvest</h1>
            <h4>Harvest es una aplicación para dispositivos móviles, tablets, integrada al sistema de gestión Abasto. </h4>
            <p>Está diseñada, especificamente, para la administración y control de la venta frutihortícola. <br/>
            Harvest cuenta con las herramientas necesarias para una gestión empresarial efectiva y acorde a las nuevas tecnologías, permitiendo mantener un control total de las operaciones, desde cualquier ubicación:<br/>
            <ul>
                <li> Notas de pedidos: Registro de manera rápida y sencilla de los pedidos de los clientes desde cualquier lugar. Armado y gestión de varias notas de pedidos de forma simultánea, garantizando un servicio ágil y sin errores.</li>
                <li> Control de vacíos: Simplificación de la gestión de vales de vacíos con Harvest. Herramientas dinámicas como lectora de código de barras de dichos vales. Registro, control y administración de los vales de manera centralizada, evitando pérdidas o malentendidos.</li>
                <li> Control de piso: Gestión y supervisión de las actividades operativas en el puesto, desde la tablet. Monitoreo del stock en tiempo real. Garantización de las operaciones y los resultados. Seguimiento de promedios para un conocimiento efectivo de las operaciones, las ventas y los resultados.</li>
            </ul>
            Harvest es un sistema moderno para una administración empresarial eficaz y conveniente.
            </p>

            <Button href='/servicios' variant="outlined" color='terciary'>Volver a servicios</Button>

        </div>
    )
}

export default Harvest