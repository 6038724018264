import { Box, Button } from '@mui/material';
import ServicioDetalle from '../ServicioDetalle/ServicioDetalle';
import useMediaQuery from '@mui/material/useMediaQuery';



const Servicio = ( {servicio} ) => {
    const matches = useMediaQuery("(min-width:600px)");

    return (
            <div className={servicio.categoria} >
                <div className="tooltip">
                <img src={servicio.imag}  alt="foto" className={servicio.categoriaImg}/>

                <div className='planeta'></div>
                    <div className="left">
                        <div className="overflow-foto-tooltip">
                            <img src={servicio.foto} className="foto-tooltip" alt="foto descriptiva"/>
                        </div>
                        <h3>{servicio.titulo}</h3>
                        <p>{servicio.resumen}</p>
                        {/*<div className='texto-desc' ><p>{servicio.descripcion}</p>
                        </div>*/}
                        {/*<ServicioDetalle  servicio={servicio}/>*/}
                        <Button href={servicio.pagina} variant="outlined">Ver más</Button>
                    </div>
                </div>

            </div>
    )
}

export default Servicio