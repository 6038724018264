import { Button, ButtonGroup} from '@mui/material';
import {Link } from 'react-router-dom';
import {React} from 'react'

import useMediaQuery from '@mui/material/useMediaQuery';


function Botonera(){
    const matches = useMediaQuery("(min-width:600px)");


        return <>
        <ButtonGroup variant={`${matches ? `font` : `text`}`} color="primary" size={`${matches ? `medium` : `small`}`}>
            <Button component={Link} to={'/nosotros'}>Nosotros</Button>
            <Button href={'/servicios'}> Servicios</Button>
            <Button component={Link} to={'/contacto'} >Contacto</Button>
        </ButtonGroup>
    </>
    }
    


export default Botonera;